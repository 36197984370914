.pageContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.maxWidth {
    max-width: 1080px;
}

/* hero section */

.heroSectionContainer {
    text-align: center;
    display: flex;
    position: fixed;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.heroDescription {
    max-width: 60%;
    font-size: 18px;
}

.ctaContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.requestButton,
.applyButton {
    display: flex;
    max-width: 250px;
    padding: 16px 20px;
    justify-content: center;
    border-radius: 10px;
    align-items: center;
    color: #f5f5f5;
    cursor: pointer;
}

.requestButton {
    background: linear-gradient(180deg, #53a548 0%, #4c934c 100%);
}

.applyButton {
    background: #7d84b2;
}

.button {
    position: relative;
    overflow: hidden;
}

.button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, #ffffff66, transparent);
    transition: 0.3s all;
}

.button:hover::before {
    left: 100%;
}

.buttonDesc {
    font-size: 14px;
    color: #aaa;
}

/* bento box section */

.bentoBoxContainer {
    z-index: 0;
    position: relative;
    padding: 0 1em;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: -5vh;
}

#abc-logo {
    max-width: 24px;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
}

.oneColumn {
    width: 32.5%;
    flex-direction: column;
    gap: 24px;
    display: flex;
    max-width: 250px;
}

.twoColumn {
    width: 65%;
    flex-direction: column;
    display: flex;
}

.bentoBox {
    border-radius: 30px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.cream {
    background: #ecebe4;
    cursor: pointer;
}

.green {
    background: linear-gradient(180deg, #4c934c 0%, #53a548 100%);
    color: #f5f5f5;
    cursor: pointer;
}

.brown {
    background: #36311f;
    color: #f5f5f5;
    height: 100%;
}

.brownPurple {
    color: #f5f5f5;
    background: linear-gradient(180deg, #36311f 34.38%, #7d84b2 100%);
}

.column {
    flex: 1; /* Make columns take equal space */
}

.twoColText {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
}

/* Gradient effect */
.gradient {
    position: absolute;
    width: 200px;
    height: 200px;
    z-index: 999;
    border-radius: 50%;
    background: radial-gradient(rgba(255, 255, 255, 0.5), transparent);
    transform: translate(-50%, -50%);
    pointer-events: none;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease-in-out; /* Add a transition effect */
}

/* footer */
.footerContent {
    padding: 2em 0;
}
.contactInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #aaa;
}

@media screen and (max-width: 768px) {
    .twoColText {
        flex-direction: column;
        width: 100%; /* Stack columns on top of each other */
    }

    .heroSectionContainer {
        padding: 0 1em;
        gap: 16px;
    }

    .heroDescription {
        max-width: 100%;
        margin-top: -12px;
    }

    .buttons {
        gap: 16px;
        margin-top: 32px;
    }

    .row {
        flex-direction: column;
    }

    .oneColumn {
        width: 100%;
        max-width: fit-content;
    }

    .twoColumn {
        width: 100%;
    }

    .footerContainer {
        margin-top: 10em;
    }

    #flipped {
        flex-direction: column-reverse;
    }
}
