body {
    margin: 0;
    font-family: "Inter", san-serif, -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "BIZ UDPGothic", sans-serif;
    font-weight: 700;
}

h1 {
    font-size: 5em;
}

h2 {
    font-size: 1.8em;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

html {
    background-color: #f5f5f5;
}

* {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: inherit;
}
